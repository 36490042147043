import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { LocalStorageManager } from '../../util/localStorageManager'
import TopHeader from '../../component/TopHeader'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import CustomButton from '../../component/CustomButton'

const PlanCheck = () => {
  const navigate = useNavigate()

  const [currentUser, setCurrentUser] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  useEffect(() => {
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    setCurrentUser(user)

    const script = document.createElement('script')
    script.src = 'https://checkout.pay.jp/'
    script.className = 'payjp-button'
    script.setAttribute('data-key', process.env.REACT_APP_PAYJP_PUBLICKEY)
    script.setAttribute('data-submit-text', 'トークンを作成する')
    script.setAttribute('data-partial', 'true')
    script.async = true
    document.querySelector('#checkout').appendChild(script)
    return () => {
      window.PayjpCheckout = null
    }
    // eslint-disable-next-line
  }, [])

  const handlePayment = (num) => {
    if (currentUser?.planStatus && currentUser.planId === num) {
      navigate('/plan-card')
      return
    }
    if (isSubmitting) return

    if (currentUser.planId !== num && currentUser.planStatus === true) {
      setIsSubmitting(true)
      axios
        .post('/api/storePayment', { amount: num, token: 'exist', userId: currentUser?._id })
        .then((res) => {
          alert(res.data?.message ?? '操作成功!')
          if (res.data.user) {
            LocalStorageManager.setObjectItem(LocalStorageManager.user, res.data.user)
            setCurrentUser(res.data.user)
          }
          if (res.data.store) {
            LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
          }
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'エラー')
        })
        .finally(() => {
          setIsSubmitting(false)
          window.PayjpCheckout = null
        })
      return
    }
    document.querySelector('input[name="payjp-token"]').value = null
    const button = document.querySelector('#payjp_checkout_box').children[0]
    button.click()
    const handleInputChange = (mutations) => {
      if (mutations[0].target.value) {
        setIsSubmitting(true)
        axios
          .post('/api/storePayment', { amount: num, token: mutations[0].target.value, userId: currentUser?._id })
          .then((res) => {
            alert(res.data?.message ?? '操作成功!')
            if (res.data.user) {
              LocalStorageManager.setObjectItem(LocalStorageManager.user, res.data.user)
            }
            if (res.data.store) {
              LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
            }
          })
          .catch((error) => {
            alert(error.response?.data?.message ?? 'エラー')
          })
          .finally(() => {
            window.PayjpCheckout = null
            window.location.reload()
          })
      }
    }

    const observer = new MutationObserver(handleInputChange)
    observer.observe(document.querySelector('input[name="payjp-token"]'), {
      attributes: true,
      attributeFilter: ['value'],
    })
  }

  const handleDeletePlan = () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    axios
      .delete('/api/deletePlan/' + currentUser?._id)
      .then((res) => {
        setIsSubmitting(false)
        toggle()
        LocalStorageManager.setObjectItem(LocalStorageManager.user, res.data.user)
        setCurrentUser(res.data.user)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'プランの確認'} isGo={true} value={'/setting'} />
      {currentUser?.planStatus === false && (
        <div className='deleted-bar text-white text-center mb-3' style={{ marginTop: '-24px' }}>
          プランが終了しました。
        </div>
      )}
      <div className='grid-default-header pt-0'>
        <div id='checkout' className='d-none'></div>
        <div className='policy-card-box'>
          <div className='policy-card-body'>
            {currentUser?.planId === '1' && currentUser?.planStatus === true ? (
              <div className='text-frame color-gray-main mb-3'>現在契約中のプラン</div>
            ) : currentUser?.planId === '1' ? (
              <div className='text-frame color-gray-main mb-3'>終了したプラン</div>
            ) : (
              ''
            )}
            {currentUser?.requestedPlanId === '1' && <div className='text-frame color-gray-main mb-3'>現在リクエスト中のプラン</div>}
            <div className='color-gray-font text18 roboto'>Salon call me</div>
            <div className='color-gray-font text-end'>
              <span className='roboto-medium color-gray-main through'>¥ 1,980</span>
              <span className='roboto color-gray-main mx-2'>→</span>
              <span className='roboto'>¥ 770</span>
              <span className='color-gray-main text-medium'> / 月</span>
            </div>
            <div>
              <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
              <span className='color-gray-font'>SMS予約内容送信機能</span>
            </div>
            <div>
              <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
              <span className='color-gray-font'>コネクティックサポート機能</span>
            </div>
          </div>
          <div className='policy-card-footer cursor-pointer' onClick={() => handlePayment('1')}>
            {currentUser?.planId === '1' ? (
              currentUser?.planStatus ? (
                <span>カード情報を確認する</span>
              ) : (
                <span>このプランで再開する</span>
              )
            ) : currentUser?.planStatus ? (
              <span>このプランで変更する</span>
            ) : (
              <span>このプランに開始する</span>
            )}
            <i className='fa fa-angle-right ms-3'></i>
          </div>
        </div>
        <div className='policy-card-box mt-4 hide'>
          <div className='policy-card-body'>
            {currentUser?.planId === '2' && currentUser?.planStatus === true ? (
              <div className='text-frame color-gray-main mb-3'>現在契約中のプラン</div>
            ) : currentUser?.planId === '2' ? (
              <div className='text-frame color-gray-main mb-3'>終了したプラン</div>
            ) : (
              ''
            )}
            {currentUser?.requestedPlanId === '2' && <div className='text-frame color-gray-main mb-3'>現在リクエスト中のプラン</div>}
            <div className='color-gray-font'>
              <span className='roboto text18'>Salon call me</span>
              <span className=' ms-1'>プラス</span>
            </div>
            <div className='color-gray-font text-end'>
              <span className='roboto-medium color-gray-main through'>¥ 3,330</span>
              <span className='roboto color-gray-main mx-2'>→</span>
              <span className='roboto'>¥ 1,480</span>
              <span className='color-gray-main text-medium'> / 月</span>
            </div>
            <div>
              <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
              <span className='color-gray-font'>SMSで予約内容の送付</span>
            </div>
            <div>
              <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
              <span className='color-gray-font'>安心クレジット払い機能</span>
            </div>
            <div>
              <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
              <span className='color-gray-font'>新規電話番号取得＆電話機能</span>
            </div>
          </div>
          <div className='policy-card-footer cursor-pointer' onClick={() => handlePayment('2')}>
            {currentUser?.planId === '2' ? (
              currentUser?.planStatus ? (
                <span>カード情報を確認する</span>
              ) : (
                <span>このプランで再開する</span>
              )
            ) : currentUser?.planStatus ? (
              <span>このプランで変更する</span>
            ) : (
              <span>このプランに開始する</span>
            )}
            <i className='fa fa-angle-right ms-3'></i>
          </div>
        </div>
        <div className='mt-4 pb-5 text-center'>
          {currentUser?.planStatus && (
            <span className='color-red cursor-pointer text-medium' onClick={toggle}>
              解約する
            </span>
          )}
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggle} className='cancel-modal logout-modal' centered size='sm'>
        <ModalBody>
          <div className='d-flex flex-column align-items-center pt-3'>
            <div className='text-center color-gray-accent text20 mb-3'>解約確認</div>
            <div className='color-gray-sub text16 text-medium mb-4 pb-2 mx-3'>
              解約を行うとすぐにすべての機能が利用できなくなります。
              <br />
              よろしいでしょうか？
            </div>
            <CustomButton text={'解約する'} color={'error'} handleOnClick={() => handleDeletePlan()} />
            <div className='text-center color-gray text-medium mt-3'>
              <span className='cursor-pointer text16' onClick={toggle}>
                閉じる
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default PlanCheck
