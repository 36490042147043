import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Spinner } from 'reactstrap'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomButton from '../../component/CustomButton'
import StoreHeader from '../../component/StoreHeader'

const StoreRegisterThird = () => {
  const navigate = useNavigate()
  const [isPayed, setIsPayed] = useState(false)
  const [store, setStore] = useState({
    storeStage: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  useEffect(() => {
    const exist = LocalStorageManager.existItem(LocalStorageManager.store)
    if (exist) {
      alert('すでに店舗を登録済みです。')
      navigate('/home')
      return
    }

    const script = document.createElement('script')
    script.src = 'https://checkout.pay.jp/'
    script.className = 'payjp-button'
    script.setAttribute('data-key', process.env.REACT_APP_PAYJP_PUBLICKEY)
    script.setAttribute('data-submit-text', 'トークンを作成する')
    script.setAttribute('data-partial', 'true')
    script.async = true
    document.querySelector('#checkout').appendChild(script)

    const store2 = LocalStorageManager.getObjectItem(LocalStorageManager.tempStore)
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    if (user.planStatus === true) {
      setIsPayed(true)
      setStore({ ...store, ...store2, storeStage: user.planId })
    } else {
      setStore({ ...store, ...store2 })
    }
    return () => {
      window.PayjpCheckout = null
    }
    // eslint-disable-next-line
  }, [])

  const stepNext = () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    LocalStorageManager.setObjectItem(LocalStorageManager.tempStore, store)
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    const params = { ...store, userId: user._id, storeNumber: store.storeNumber, registrantNumber: store.registrantNumber }
    axios
      .post('/api/storeRegister', params)
      .then((res) => {
        LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
        navigate('/store-register-last')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handlePayment = (num) => {
    if (isSubmitting) return
    setStore({ ...store, storeStage: num })
    // setIsPayed(true);
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    const button = document.querySelector('#payjp_checkout_box').children[0]
    button.click()
    const handleInputChange = (mutations) => {
      if (mutations[0].target.value) {
        setIsSubmitting(true)
        axios
          .post('/api/storePayment', { amount: num, token: mutations[0].target.value, userId: user._id })
          .then((res) => {
            setIsPayed(true)
            setIsSubmitting(false)
            LocalStorageManager.setObjectItem(LocalStorageManager.user, res.data.user)
          })
          .catch((error) => {
            alert(error.response?.data?.message ?? 'エラー')
            console.log(error)
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      }
    }

    const observer = new MutationObserver(handleInputChange)
    observer.observe(document.querySelector('input[name="payjp-token"]'), {
      attributes: true,
      attributeFilter: ['value'],
    })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <StoreHeader title={'プラン選択'} page={3} value={'/store-register-second'} />
      <div className='grid-default-header pt-0 pb-5'>
        <div id='checkout' className='d-none'></div>
        {!isPayed ? (
          isSubmitting ? (
            <div className='pt-5 text-center color-gray-main text20'>
              <Spinner />
              <div>処理中</div>
            </div>
          ) : (
            <div className=''>
              <div className='policy-card-box'>
                <div className='policy-card-body'>
                  <div className='color-gray-font text18 roboto'>Salon call me</div>
                  <div className='color-gray-font text-end'>
                    <span className='roboto-medium color-gray-main through'>¥ 1,980</span>
                    <span className='roboto color-gray-main mx-2'>→</span>
                    <span className='roboto'>¥ 770</span>
                    <span className='color-gray-main text-medium'> / 月</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>SMS予約内容送信機能</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>コネクティックサポート機能</span>
                  </div>
                </div>
                <div className='policy-card-footer cursor-pointer' onClick={() => handlePayment('1')}>
                  <span>このプランではじめる</span>
                  <i className='fa fa-angle-right ms-3'></i>
                </div>
              </div>
              <div className='policy-card-box mt-4 hide'>
                <div className='policy-card-body'>
                  <div className='color-gray-font'>
                    <span className='roboto text18'>Salon call me</span>
                    <span className=' ms-1'>プラス</span>
                  </div>
                  <div className='color-gray-font text-end'>
                    <span className='roboto-medium color-gray-main through'>¥ 3,330</span>
                    <span className='roboto color-gray-main mx-2'>→</span>
                    <span className='roboto'>¥ 1,480</span>
                    <span className='color-gray-main text-medium'> / 月</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>SMSで予約内容の送付</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>安心クレジット払い機能</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>新規電話番号取得＆電話機能</span>
                  </div>
                </div>
                <div className='policy-card-footer cursor-pointer' onClick={() => handlePayment('2')}>
                  <span>このプランではじめる</span>
                  <i className='fa fa-angle-right ms-3'></i>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className=''>
            <div className='color-gray-accent text20 text-center'>申し込みが完了しました</div>
            {store.storeStage === '1' ? (
              <div className='policy-card-box mt-3'>
                <div className='policy-card-body'>
                  <div className='color-gray-font text18 roboto'>Salon call me</div>
                  <div className='color-gray-font text-end'>
                    <span className='roboto-medium color-gray-main through'>¥ 1,980</span>
                    <span className='roboto color-gray-main mx-2'>→</span>
                    <span className='roboto'>¥ 770</span>
                    <span className='color-gray-main text-medium'> / 月</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>SMS予約内容送信機能</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>コネクティックサポート機能</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className='policy-card-box mt-4'>
                <div className='policy-card-body'>
                  <div className='color-gray-font'>
                    <span className='roboto text18'>Salon call me</span>
                    <span className=' ms-1'>プラス</span>
                  </div>
                  <div className='color-gray-font text-end'>
                    <span className='roboto-medium color-gray-main through'>¥ 3,330</span>
                    <span className='roboto color-gray-main mx-2'>→</span>
                    <span className='roboto'>¥ 1,480</span>
                    <span className='color-gray-main text-medium'> / 月</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>SMSで予約内容の送付</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>安心クレジット払い機能</span>
                  </div>
                  <div>
                    <i className='fa fa-check color-gray-main pe-2 mt-3'></i>
                    <span className='color-gray-font text-medium'>新規電話番号取得＆電話機能</span>
                  </div>
                </div>
              </div>
            )}
            <div className='mt-5'></div>
            <CustomButton text={'次へ'} handleOnClick={() => stepNext()} />
          </div>
        )}
      </div>
    </div>
  )
}

export default StoreRegisterThird
